const eabcheckboxes = [
    {
      id: 'eabchk1',
      name: '$10000 – $50000',
      key: '10000-50000',
      label: '$10,000 – $50,000',   
    },
    {
      id: 'eabchk2',
      name: '$50000 – $100000',
      key: '50000-100000',
      label: '$50,000 – $100,000',
    },
    {
      id: 'eabchk3',
      name: '$100000 – $150000',
      key: '100000-150000',
      label: '$100,000 – $150,000',
    },
    {
      id: 'eabchk4',
      name: '$150000+',
      key: '150000+',   
      label: '$150,000+',
    },
  ];
  
  export default eabcheckboxes;