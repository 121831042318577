const checkboxes = [
    {
      id: 'chk1',
      name: 'Name 1',
      key: 'name1',      
      description: 'Finntia is a diverse software development firm based in Helsinki, Finland with its root from India. We are a group of nerds with an eye for design and mind with logic.'
    },
    {
      id: 'chk2',
      name: 'Name 2',
      key: 'name2',
      description: 'Finntia is a diverse software development firm based in Helsinki, Finland with its root from India. We are a group of nerds with an eye for design and mind with logic.'
    },
    {
      id: 'chk3',
      name: 'Name 3',
      key: 'name3',
      description: 'Finntia is a diverse software development firm based in Helsinki, Finland with its root from India. We are a group of nerds with an eye for design and mind with logic.'
    },
    {
      id: 'chk4',
      name: 'Name 4',
      key: 'name4',      
      description: 'Finntia is a diverse software development firm based in Helsinki, Finland with its root from India. We are a group of nerds with an eye for design and mind with logic.'
    },
    {
      id: 'chk5',
      name: 'Name 5',
      key: 'name5',
      description: 'Finntia is a diverse software development firm based in Helsinki, Finland with its root from India. We are a group of nerds with an eye for design and mind with logic.'

    },
    {
      id: 'chk6',
      name: 'Name 6',
      key: 'name6',
      description: 'Finntia is a diverse software development firm based in Helsinki, Finland with its root from India. We are a group of nerds with an eye for design and mind with logic.'
    },
  ];
  
  export default checkboxes;