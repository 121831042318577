import React, { Component } from "react"
import { graphql } from "gatsby"
import axios from 'axios'

import checkboxes from '../components/checkboxes';
import CheckBox from '../components/CheckBox';
import eabcheckboxes from '../components/eabcheckboxes';
import EabCheckBox from '../components/EabCheckBox';
import SEO from "../components/seo"
import Typed from 'react-typed';

const validEmailRegex = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
const validPhoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

class GetInTouch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitted: false,
			status: "",
			fullname: null,
			email: null,
			phone: null,
			company: null,
			checkedItems: new Map(),
			eabcheckedItems: new Map(),
			errors: {
				fullname: '',
				email: '',
				phone: '',
				company: '',
				checkedItems: '',
				eabcheckedItems: '',
			}
		};
	}
	 
	allFieldsValid() {		
		const fields = ['fname','email','phone','company','checkedItems','eabcheckedItems'];
		const errors = {};
		
		fields.forEach((field) => {
		  const value = this.state[field];
			if (!value || value === '') {
				errors[field] = "This field cannot be blank.";
			} 
			
			if (field === "email") {
				if(!value || value === '') {
					console.log("Email is blank");
					errors.email = "This field cannot be blank.";
				} else if (!value.match(validEmailRegex)) {
					console.log("Email dose not match..");
					errors.email = "Please enter a valid email address";
				} 
			}
			if (field === "phone") {
				if(!value || value === '') {
				  errors.phone = "This field cannot be blank.";
				} else if (!value.match(validPhoneRegex)) {
				  errors.phone = "Please enter only digits (0-9)";
				}
			  }
		}); 
	   
		this.setState({ errors });
		return Object.keys(errors).length === 0;		
	}

	handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		this.setState({[name]: value});
	}

	handlechkChange = (event) => {		
		const item = event.target.name;		
		const isChecked = event.target.checked;		
		this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));		
	}

	handleeabchkChange = (event) => {		
		const item = event.target.name;		
		const isChecked = event.target.checked;		
		this.setState(prevState => ({ eabcheckedItems: prevState.eabcheckedItems.set(item, isChecked) }));		
	}

	handleSubmit = (event) => {
		event.preventDefault();		
		if(this.allFieldsValid()) {
			const { fname, email, phone, company, checkedItems, eabcheckedItems } = this.state;
			let chkkeys = Array.from( checkedItems.keys() );
			const strch = chkkeys.toString();

			let eabchkkeys = Array.from( eabcheckedItems.keys() );
			const streabch = eabchkkeys.toString();

			axios.post('https://finntia.phptasks.com/wp-json/finntia/v1/get-in-touch', {
				'fname': fname,
				'email': email,
				'phone': phone,
				'company': company,
				'checkedItems': strch,
				'eabcheckedItems': streabch,
			})
			.then((response) => {
				this.setState({
				submitted: true,
				status: "Thank you. We've received your Inquiry. We'll get back to you soon."
				});
			})
			.catch((error) => {
				console.log(error);
			});
		} else {
			this.setState({
				submitted: false,
				status: "There was a problem with your submission. Errors are marked below."
			});
		}
	}
	
	render() {		
		const data = this.props.data
		const banner = data.wordpressPage.acf
		var oldString = banner.banner_blink_word;
		var mynewarray=oldString.split(',')
		return(
			<>
				<SEO title="Get In Touch" />
				<div className="page-header">
					<div className="container">
						<div className="row">
							<div className="col-md-12">								
								<h1>{banner.banner_content} <Typed strings={mynewarray} typeSpeed={120} backSpeed={50} cursorChar= {'_'} loop /></h1>
							</div>
						</div>
					</div>
				</div>
				<section className="inquiry-page">
					<div className="container">
						<div className="top-content">
							<div className="row">
								<div className="col-md-6">
									<div className="text">
										<p>Finntia is a diverse software development firm based in Helsinki, Finland with its root from India. We are a group of nerds with an eye for design and mind with logic.</p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="text">
										<p>We create the magic of turning your ideas into reality. their dreams with the use of modern technology...</p>
									</div>
								</div>
							</div>
						</div>
						<h3 className={this.state.submitted === true ? 'mail-send' : this.state.submitted === false ? 'mail-failed' : '' } >{this.state.status}</h3>
						{this.state.submitted === false && 
						<form method="post" action="#" className="inquiry-form-wraper" onSubmit={this.handleSubmit} noValidate>							
							<div className="top-form">
								<div className="row form-row">
									<div className="col-md-6 field-holder">
										<div className="field">
											<input type="textbox" id="fname" name="fname" placeholder="Name" onChange={this.handleChange} noValidate/>
											{this.state.errors.fname && <span className='error'>{this.state.errors.fname}</span>}
										</div>
									</div>
									<div className="col-md-6 field-holder">
										<div className="field">
											<input type="email" id="email" name="email" placeholder="Email" onChange={this.handleChange} noValidate />
											{this.state.errors.email && <span className='error'>{this.state.errors.email}</span>}
										</div>
									</div>
									<div className="col-md-6 field-holder">
										<div className="field">
											<input type="text" id="phone" name="phone" placeholder="Phone no" onChange={this.handleChange} noValidate/>
											{this.state.errors.phone && <span className='error'>{this.state.errors.phone}</span>}
										</div>
									</div>
									<div className="col-md-6 field-holder">
										<div className="field">
											<input type="textbox" id="company" name="company" placeholder="Company" onChange={this.handleChange} noValidate />
											{this.state.errors.company && <span className='error'>{this.state.errors.company}</span>}
										</div>
									</div>
								</div>
							</div>
							<div className="form-wrap about-needs">
								<h2 className="title"> 
									Tell Us About Your Needs
									<span>(Select all that apply)</span>
								</h2>
								<div className="needs-list">
									<div className="row">
										<React.Fragment>
											{checkboxes.map(item => (
												<div className="col-md-6" key={item.id}>
													<div className="check-wrap">
														<label>											
															<CheckBox name={item.name} checked={this.state.checkedItems.get(item.name)} onChange={this.handlechkChange} />
															<span>{item.name}</span>
															<p>{item.description}</p>
														</label>
													</div>
												</div>
											))}
										</React.Fragment>
									</div>									
								</div>
							</div>
							<div className="form-wrap annual-budget">
								<h2 className="title">Estimated Annual Budget</h2>
								<div className="row">
									<React.Fragment>
										{eabcheckboxes.map(item => (
											<div className="col-md-6 col-lg-3"  key={item.id}>
												<label>											
													<EabCheckBox name={item.name} checked={this.state.eabcheckedItems.get(item.name)} onChange={this.handleeabchkChange} />
													<span>{item.label}</span>
												</label>
											</div>
										))}
									</React.Fragment>
								</div>
							</div>
							<div className="submit-btn">
								<button className="btn btn-primary" type="submit">Submit</button>
							</div>
						</form>}
					</div>
				</section>
			</>
		)
	}
}

export default GetInTouch;

export const query = graphql`
{
	wordpressPage(wordpress_id: {eq: 19}) {
		acf {
			banner_heading
			banner_content
			banner_blink_word
		}
	  }
    allWordpressPage(filter: {wordpress_id: {eq: 260}}) {
        edges {
          node {
            acf {
              contact_heading
              contact_content
              contact_form_shortcode
              google_map
            }
          }
        }
    }
}
`
